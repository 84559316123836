import React from 'react'

export default function Button({ children, style, className }) {
  return (
    <div
      style={style}
      className={`text-2xl lg:text-3xl font-light text-gray-800 mt-2 mb-2 ${className}`}>
      {children}
    </div>
  )
}
