import React, { useEffect, useState } from 'react'
import PhotoContainer from '../components/PhotoContainer'
import Loader from '../components/UI/Loader'
import Logo from '../components/UI/Logo'
import Title from '../components/UI/Title'
import Popup from '../components/UI/Popup'
import Headers from '../components/UI/Headers'
import Products from '../components/Products'
import Cart from '../components/Cart'
import NotFound from '../components/NotFound'
import OptIn from '../components/OptIn'
import { findSession, createOrder, addProduct, removeProduct, getProducts, finalizeOrder, refreshOrder, updateCustomer, setOptIn } from '../api'
import Button from '../components/UI/Button'
import { FaAmazon } from 'react-icons/fa'
import Ads from '../components/Ads'


export default function Photos() {
  const [session, setSession] = useState()
  const [products, setProducts] = useState(null)
  const [cartOpen, setCartOpen] = useState()
  const [popup, setPopup] = useState(false)
  const fetchData = async () => {
    setSession(await findSession())
    setProducts(await getProducts())
  } 
  useEffect(fetchData, [])
  const addToCart = async product => {
    let order = session.order
    if (!session.order)
      order = await createOrder()
    order = await addProduct(order, product)
    // console.log(order)
    if (order.invoice) {
      const shipping = products.find(p => p.type === 'shipping' && order.invoice.lines.data.map(l => l.price.product).includes(p.stripeId))
      if (shipping) {
        await removeProduct(session.order, shipping)
        order = await addProduct(session.order, shipping)
      }
    }
    setSession({ ...session, order })
    setCartOpen(true)
  }
  const removeFromCart = async product => {
    let order = await removeProduct(session.order, product)
    if (order.error) return
    const shipping = products.find(p => p.type === 'shipping' && order.invoice.lines.data.map(l => l.price.product).includes(p.stripeId))
    if (shipping) {
      await removeProduct(session.order, shipping)
      order = await addProduct(session.order, shipping)
    }
    setSession({ ...session, order })
    if (!order.invoice)
      setCartOpen(false)
  }
  const finalize = async _ => {
    if (session.order.invoice.client_secret)
      return session.order.invoice
    const order = await finalizeOrder(session.order)
    setSession({ ...session, order })
    return order.invoice
  }
  const refresh = async _ => {
    const order = await refreshOrder(session.order)
    setSession({ ...session, order })
  }
  const putOptIn = async value => {
    setOptIn(value)
    setSession({ ...session, optIn: value })
  }
  if (session === undefined) return <Loader />
  if (session === false || session.error) return <NotFound />
  return (
    <div>
      <OptIn session={session} putOptIn={putOptIn} />
      {popup &&
        <Popup close={() => setPopup(false)}>
          <div className="flex flex-col justify-center items-center">
              <img src="/logo_mcc.png" className="h-20 mb-6 mt-2" />
              <img src="/illustration_mcc.jpg" className="hidden lg:block h-48 rounded-md mb-2" />

            <div className="text-base text-left px-2 my-2">
              <p className="my-2">Lors du Salon de la Photo du 6 au 9 octobre 2022, la Cabine Photo Studio Harcourt Paris soutient l’association <strong>Mécénat Chirurgie Cardiaque</strong>.</p>
              <p className="my-2">Dans toutes les cabines Studio Harcourt et pour l’achat d’un portrait à 10 euros, 3 euros seront reversés à Mécénat Chirurgie Cardiaque pendant toute la période du salon.</p>
              <p className="my-2">Merci à tous</p>
            </div>
            <div className="flex">
              <Button className="m-2" href={"https://don.mecenat-cardiaque.org/mon-don/~mon-don?utm_source=menu&utm_medium=bouton&utm_campaign=2022"}>Faire un don</Button>
              <Button className="m-2" onClick={() => setPopup(false)}>Fermer</Button>
            </div>
          </div>
        </Popup>
      }

      <Headers title="Votre photo Cabine Studio Harcourt" photo={session.photo.url} />
      {session.booth && session.booth && session.booth.name !== 'Leetchi' && <Logo />}
      <div className="flex justify-center flex-col lg:flex-row items-start">
        {session.booth && session.booth.displayProducts &&
          <div className="hidden lg:flex flex-row lg:flex-col lg:justify-center max-w-full overflow-x-auto px-2 lg:mt-6 lg:px-0" style={{ height: '87.5vh', maxHeight: 'calc(770px + 3rem)' }}>
            <Ads />
          </div>
        }
        <PhotoContainer url={session.photo.url} session={session} />
        {session.booth && session.booth.displayProducts &&
          <Products
            products={products}
            order={session.order}
            openCart={() => setCartOpen(true)}
            add={addToCart} />
        }
      </div>
      {cartOpen &&
        <Cart
          order={session.order}
          removeProduct={removeFromCart}
          addProduct={addToCart}
          products={products}
          finalize={finalize}
          updateCustomer={data => updateCustomer(session.order, data)}
          refresh={refresh}
          close={() => setCartOpen(false)} />
      }
    </div>
  )
}
