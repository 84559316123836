import React from 'react'

export default function FormElement({ title, children, className, error }) {
  const messages = {
    'pattern': 'Le format est erroné',
    'required': 'Ce champ est requis'
  }
  return (
    <label className={`block pl-2 py-2 w-1/2 text-gray-700 ${className} ${error && 'text-red-800'}`}>
      <div className="flex justify-between text-sm mb-1 font-light pr-2">
        {title}
        {error &&
          <div className="mt-2 leading-none font-light text-xs">
            {error.type ? messages[error.type] : `${error}`}
          </div>
        }
      </div>
      {children}
    </label>
  )
}
