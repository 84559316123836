import React, { useState } from 'react'
import Popup from './UI/Popup'
import Title from './UI/Title'
import Checkbox from './UI/Checkbox'
import Button from './UI/Button'

export default function OptIn({ session, putOptIn }) {
  const [optIn, setOptIn] = useState(false)
  const [close, setClose] = useState(false)
  if (session.optIn !== null || close) return null
  return (
    <Popup close={() => setClose(true)}>
      <Title>Demande d'autorisation</Title>
      <div className="flex flex-col justify-center items-center">
        <div className="text-xs text-left px-2 my-2">
          <p className="my-2">L’utilisateur assume la responsabilité des données personnelles des tiers obtenues, publiées ou partagées par cette application et confirme avoir obtenu le consentement de ces derniers pour transmettre leurs données.</p>
          <p className="my-2">L’ensemble des données seront conservées durant 6 mois. Wizito agit en qualité de responsable de traitement.</p>
          <p className="my-2">Conformément au Règlement (UE) 2016/679, dit Règlement Général sur la Protection des Données, du 27 Avril 2016, vous disposez des droits d’accès, de rectification et de suppression des données personnelles vous concernant, en écrivant au Délégué à la Protection des Données de Wizito à l’adresse email suivante : <u>help@wizito.com</u></p>
          <p className="my-2">Vous disposez également du droit d’introduire une réclamation auprès de la CNIL.</p>
        </div>
        <div className="flex items-center m-4 text-left cursor-pointer text-sm" onClick={() => setOptIn(!optIn)}>
          <Checkbox className="mr-4" checked={optIn} />
          J’accepte de recevoir des informations de la part {(session.booth && session.booth.name !== 'Leetchi') ? 'du Studio Harcourt' : 'de Leetchi'}.
        </div>
        <Button className="m-2" onClick={() => putOptIn(optIn)}>Valider</Button>
      </div>
    </Popup>
  )
}
