import React from 'react'
import { FiLoader } from 'react-icons/fi'
import Headers from './Headers'

export default function Loader() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Headers title="" />
      <FiLoader className="h-12 w-12 stroke-1 rotating box-border" />
    </div>
  )
}
