import React, { useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { mod } from '../../utils'

export default function Slider({ photos, className }) {
  const [element, setElement] = useState(0)
  const handlers = useSwipeable({
    onSwipedRight: _ => setElement(mod(element - 1, photos.length)),
    onSwipedLeft: _ => setElement(mod(element + 1, photos.length))
  })

  return (
    <div className={`${className}`}>
      <div
        {...handlers}
        className="flex transition-all duration-150 -ml-2 p-4 box-border"
        style={{ transform: `translateX(-${element * 100}%) translateX(${element * 1}rem)` }}>
        {photos.map((photo, index) =>
          <div
            onClick={() => setElement(index)}
            className={`w-full h-64 mx-2 flex-shrink-0 bg-cover bg-center rounded-sm transition-opacity duration-150 ${index === element ? '' : 'opacity-25 cursor-pointer'}`}
            style={{ backgroundImage: `url("${photo}")` }} />
        )}
      </div>
      {photos.length > 1 &&
        <div className="flex justify-center my-1">
          {photos.map((_, index) =>
            <div
              onClick={() => setElement(index)}
              className={`w-6 h-6 border-8 border-white rounded-full cursor-pointer ${index === element ? 'bg-gray-500' : 'bg-gray-300'}`} />
          )}
        </div>
      }
    </div>
  )
}
