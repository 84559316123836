import React, { useState } from 'react'
import Button from './UI/Button'
import Title from './UI/Title'
import Label from './UI/Label'
import Panel from './UI/Panel'
import Checkbox from './UI/Checkbox'
import { CartProduct } from './Products'
import { FiShoppingCart } from 'react-icons/fi'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { useForm } from 'react-hook-form'
import { formatPrice } from '../utils'

const stripePromise = loadStripe('pk_live_51HAuMLATjh6Tus4ekbOggxX1d3jngKmyHqrzMKc7FDjhMwx7pVl1S5RboX2OaX8g4PmhAS0Q3BGG9H2TLS9dNEGF004IeCY4t3')

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#222222',
      fontFamily: `'Roboto'`,
      fontWeight: '300',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        fontWeight: '300',
        color: '#777777'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

function Delivery({ invoice, products, addProduct, removeProduct }) {
  const [loading, setLoading] = useState(false)
  const shippings = products.filter(p => p.type === 'shipping')
  const selectedShipping = shippings.find(shipping => invoice.lines.data.find(l => shipping.stripeId === l.price.product))
  const selectedProducts = invoice.lines.data.map(l => l.price.product)
  const price = s => s.price.filter(p => [...selectedProducts, null].includes(p.nickname)).sort((a, b) => b.unit_amount - a.unit_amount)[0]
  return (
    <>
      <Title className="text-center">Livraison</Title>
      {shippings.map(shipping =>
        <div className="flex my-2 pr-2">
          <div className="flex items-center w-full text-gray-800">
            <Checkbox
              className="mr-4"
              loading={loading}
              checked={selectedShipping === shipping}
              onClick={async () => {
                if (selectedShipping === shipping) return
                setLoading(true)
                if (selectedShipping)
                  await removeProduct(selectedShipping)
                await addProduct(shipping)
                setLoading(false)
              }} />
            <div className="flex justify-between items-center w-full">
              <div>
                {shipping.name}
                <div className="text-sm text-gray-600">{shipping.description}</div>
              </div>
              <div>
                {formatPrice(price(shipping))}
              </div>
            </div>
          </div>
        </div>  
      )}
    </>
  )
}

function CheckoutForm({ invoice, products, close, updateCustomer, finalize, refresh, addProduct, removeProduct }) {
  const { getValues, register, trigger: validateForm, errors } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', defaultValues: { email: invoice.email } })
  const [cgu, setCgu] = useState(false)
  const [cardError, setCardError] = useState(null)
  const stripe = useStripe()
  const elements = useElements()
  
  const pay = async e => {
    const valid = await validateForm()
    const { firstname, lastname, email, phone, address1, address2, zipcode, city } = getValues()
    await updateCustomer({ name: `${firstname} ${lastname}`, email, phone, address: { line1: address1, line2: address2, postal_code: zipcode, city, country: 'FR' } })
    const card = elements.getElement(CardElement)
    if (!valid) return
    if (!cgu) return setCardError('Vous devez accepter les CGU')
    const invoice = await finalize()
    try {
      console.log(invoice)
      if (invoice.error) throw invoice.error
      const result = await stripe.confirmCardPayment(invoice.client_secret, {
        payment_method: { card: card }
      })
      if (result.error) throw result.error.message
      setCardError(null)
      if (result.paymentIntent.status === 'succeeded') refresh()
    } catch (e) {
      setCardError(`${e}`)
      throw e
    }
  }

  return (
    <div>
      <Delivery invoice={invoice} addProduct={addProduct} removeProduct={removeProduct} products={products} />
      <div className="flex flex-wrap flex-row items-start justify-between w-full -ml-2">
        <Label title="Prénom" error={errors.firstname}>
          <input
            type="text"
            name="firstname"
            className="form-input w-full"
            ref={register({ required: true })} />
        </Label>
        <Label title="Nom" error={errors.lastname}>
          <input
            type="text"
            name="lastname"
            className="form-input w-full"
            ref={register({ required: true })} />
        </Label>
        <Label title="Email" error={errors.email}>
          <input
            type="text"
            name="email"
            className="form-input w-full"
            ref={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })} />
        </Label>
        <Label title="Téléphone" error={errors.phone}>
          <input
            type="text"
            name="phone"
            className="form-input w-full"
            ref={register({ required: true, pattern: /^\d{10}$/ })} />
        </Label>
        <Label title="Adresse" error={errors.address1}>
          <input
            type="text"
            name="address1"
            className="form-input w-full"
            ref={register({ required: true })} />
        </Label>
        <Label title="Complément" error={errors.address2}>
          <input
            type="text"
            name="address2"
            className="form-input w-full"
            ref={register({  })} />
        </Label>
        <Label title="Code postal" error={errors.zipcode}>
          <input
            type="text"
            name="zipcode"
            className="form-input w-full"
            ref={register({ required: true })} />
        </Label>
        <Label title="Ville" error={errors.city}>
          <input
            type="text"
            name="city"
            className="form-input w-full"
            ref={register({ required: true })} />
        </Label>
      </div>
      <Title className="text-center">Paiement</Title>
      <Label title="Carte bancaire" className="-ml-2 w-full" error={cardError}>
        <CardElement
          id="card-element"
          className="form-input w-full"
          onChange={e => setCardError(e.error ? e.error.message : null)}
          options={CARD_ELEMENT_OPTIONS} />
      </Label>
      <div className="flex items-center w-full text-gray-800 mt-4">
        <Checkbox checked={cgu} onClick={() => setCgu(!cgu)} className="mr-4" />
        <div>J'accepte <u> les conditions générales de ventes</u>.</div>
      </div>
      <div className="flex flex-col justify-center items-center mt-4">
        <Button
          variant="primary"
          className="m-2"
          Icon={FiShoppingCart}
          onClick={pay}>
          Payer {formatPrice(invoice.total)} TTC
        </Button>
        <Button
          className="m-2"
          onClick={close}>
          Annuler
        </Button>
      </div>
    </div>
  )
}

function PaidOrder({ invoice }) {
  return (
    <div className="flex flex-col justify-center items-center border border-gray-400 w-full p-12 text-center text-gray-700">
      Votre facture {invoice.number} d'un montant de {formatPrice(invoice.total)} a bien été réglée le {new Date(invoice.status_transitions.paid_at * 1000).toLocaleDateString()} à {new Date(invoice.status_transitions.paid_at * 1000).toLocaleTimeString()}
      <Button variant="primary" className="mt-4" onClick={() => window.open(invoice.hosted_invoice_url, '_blank')}>
        Accéder à mon reçu
      </Button>
    </div>
  )
}

export default function Cart({ order, products, addProduct, removeProduct, close, finalize, refresh, updateCustomer }) {
  if (!order.invoice || order.invoice.lines.total_count === 0) return <div>Panier vide</div>
  const paid = order.status === 'paid'
  return (
    <Panel
      close={close}
      title="Récapitulatif">
      <div>
        {order.invoice.lines.data.map(l => {
          const product = (products || []).find(p => p.stripeId === l.price.product)
          if (!product) return <div>Produit inconnu</div>
          return product.type === 'product' && (
            <CartProduct
              className="my-4"
              product={product}
              remove={!paid && (() => removeProduct(product))} />
          )
        })}
      </div>
      {paid &&
        <PaidOrder invoice={order.invoice} />
      }
      {['draft', 'open'].includes(order.status) &&
        <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500' }] }} >
          <CheckoutForm
            invoice={order.invoice}
            products={products}
            addProduct={addProduct}
            removeProduct={removeProduct}
            updateCustomer={updateCustomer}
            finalize={finalize}
            refresh={refresh}
            close={close}
          />
        </Elements>
      }
    </Panel>
  )
}
