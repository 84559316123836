import React from 'react'
import { FiX } from 'react-icons/fi'
import { useKeyboardEvent, DisableBodyScroll } from '../../utils'
import Title from './Title'

export default function Panel({ children, close, title }) {
  useKeyboardEvent('Escape', close)
  return (
    <>
      <DisableBodyScroll />
      <div
        onClick={close}
        className="fixed h-screen w-screen top-0 left-0 bg-black bg-opacity-50 z-10" />
      <div
        className="fixed bg-white top-0 right-0 w-full lg:w-1/3 p-5 lg:p-10 overflow-y-auto overflow-x-hidden z-10" style={{ height: window.innerHeight }}>
        <FiX
          onClick={close}
          className="absolute top-0 left-0 m-1 lg:m-4 w-8 h-8 cursor-pointer stroke-1" />
        <div className="flex flex-col justify-between min-h-full">
          <Title className="text-center -mt-4 lg:-mt-8">{title}</Title>
          {children}
        </div>
      </div>
    </>
  )
}
