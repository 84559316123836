import React from 'react'

export default function Banner({ session }) {
  if (!session || !session.booth.banner) return null
  const image = session.booth.banner.url
  return (
    <div
      className=" h-24 bg-cover bg-center mx-auto mb-4 w-full flex-shrink-0"
      style={{ backgroundImage: `url("${image}")` }} />
  )
}
