import React from 'react'
import { FaAmazon } from 'react-icons/fa'
import Button from './UI/Button'

export default function Ads() {
  return (
    <>
      <div className="flex flex-col justify-between w-32 mx-2 lg:my-4 lg:mx-0">
        <div
          className="w-24 h-24 lg:w-32 lg:h-32 mr-4 flex-shrink-0 bg-cover bg-center rounded-sm"
          style={{ backgroundImage: `url("/amazon.jpg")` }} />
        <div className="text-center my-3 font-light text-gray-800 text-sm leading-tight">
          La boutique Amazon des accessoires du Studio Harcourt
        </div>
        <Button
          Icon={FaAmazon}
          className="mx-auto"
          variant="secondary"
          onClick={() => {
            window.open('https://www.amazon.fr/s?i=merchant-items&me=A3HBGXNIX8FD&rh=p_4%3AHARCOURT&dc&marketplaceID=A13V1IB3VIYZZH&qid=1646745081&ref=sr_nr_p_4_9', '_blank')
          }}>
          Découvrir
        </Button>
      </div>
    </>
  )
}