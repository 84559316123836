import React, { useState } from 'react'
import Button from './UI/Button'
import { FiTrash2 } from 'react-icons/fi'
import Popup from './UI/Popup'
import { deleteSession } from '../api'

export default function Delete() {
  const [open, setOpen] = useState(false)
  return (
    <div className="flex flex-col lg:flex-row items-center">
      <div className="leading-tight text-right text-gray-700 text-xs w-48 hidden lg:block">
        Je souhaite supprimer ma photo et toutes les données associées
      </div>
      <Button Icon={FiTrash2} variant="icon" className="ml-2" onClick={() => setOpen(true)} />
      {open &&
        <Popup title="Confirmation" close={() => setOpen(false)} className="">
          Souhaitez-vous vraiment supprimer votre photo ?
          <br />
          Cette action est irreversible.
          <div className="flex mt-4">
            <Button
              className="m-2"
              onClick={() => setOpen(false)}>
              Annuler
            </Button>
            <Button
              className="m-2"
              variant="primary"
              onClick={() => deleteSession().then(() => window.location.reload())}>
              Supprimer
            </Button>
          </div>
        </Popup>
      }
    </div>
  )
}
