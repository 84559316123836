import React from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import Headers from './UI/Headers'

export default function NotFound() {
  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen text-lg text-center">
      <Headers titletitle="Photo introuvable" />
      <FiAlertTriangle className="h-12 w-12 stroke-1 mb-4" />
      La photo n'existe pas ou a été supprimée.
    </div>
  )
}
