import React from 'react'

export default function Logo() {
  return (
    <a href="/">
      <img
        src="/logo.jpeg"
        className="mx-auto m-2 lg:absolute top-0 left-0 lg:m-4 w-32"/>
    </a>
  )
}
