import React from 'react'
import Share from './Share'
import Delete from './Delete'
import Banner from './Banner'

export default function PhotoContainer({ url, session }) {
  return (
    <div className="flex flex-col justify-start items-center lg:h-screen p-4 lg:p-6 box-border">
      <Banner session={session} />
      <div style={{ backgroundImage: `url("${url}")`, backgroundSize: '110%', backgroundPosition: 'center', border: '1.5rem solid white', boxShadow: '3px 3px 5px rgba(0, 0, 0, .04), 5px 5px 40px rgba(0, 0, 0, .08), 0 -2px 3px rgba(0, 0, 0, .04)' }}>
        <img src={url} className="block h-7/8 rounded-sm" style={{ maxHeight: 770, opacity: 0 }} />
      </div>
      <div className="flex flex-row items-start justify-between pt-4 w-full pb-2">
        <Share photo={url} />
        <Delete />
      </div>

    </div>
  )
}
