import React, { useState } from 'react'
import Button from './UI/Button'
import { FiShare2 } from 'react-icons/fi'
import { FaTwitter, FaFacebook, FaLinkedin, FaWhatsappSquare } from 'react-icons/fa'
import Popup from './UI/Popup'
if (typeof window !== `undefined`) import('sharer.js').then(e => true)

export default function Share({ photo }) {
  const [open, setOpen] = useState(false)
  const share = () => {
    if (navigator.share)
      navigator.share({
        title: 'Photo Harcourt',
        url: photo
      })
    else
      setOpen(!open)
  }
  const iconStyle= 'w-10 h-10 m-4 cursor-pointer'
  setTimeout(() => window.Sharer.init(), 500)
  
  return (
    <div className="relative">
      <Button Icon={FiShare2} onClick={share}>Partager</Button>
      {open &&
        <Popup title="Partage" close={() => setOpen(false)}>
          <div className="flex flex-wrap justify-center text-gray-800">
            <a data-sharer="twitter" data-url={photo}>
              <FaTwitter className={iconStyle} />
            </a>
            <a data-sharer="facebook" data-url={photo}>
              <FaFacebook className={iconStyle} />
            </a>
            <a data-sharer="whatsapp" data-url={photo}>
              <FaWhatsappSquare className={iconStyle} />
            </a>
            <a data-sharer="linkedin" data-url={photo}>
              <FaLinkedin className={iconStyle} />
            </a>
          </div>
        </Popup>
      }
    </div>
  )
}
