import React from 'react'
import { FiX } from 'react-icons/fi'
import { useKeyboardEvent, DisableBodyScroll } from '../../utils'
import Title from './Title'

export default function Panel({ children, close, title }) {
  useKeyboardEvent('Escape', close)
  return (
    <>
      <DisableBodyScroll />
      <div
        className="fixed flex justify-center items-center h-screen w-screen top-0 left-0 z-30">
        <div
          onClick={close}
          className="fixed h-screen w-screen top-0 left-0 bg-black bg-opacity-50" />
        <div className="relative bg-white w-11/12 lg:w-1/3 h-auto p-4 rounded-sm">
          <FiX
            onClick={close}
            className="absolute top-0 left-0 m-1 lg:m-4 w-8 h-8 cursor-pointer stroke-1" />
          <Title className="text-center -mt-3 lg:-mt-2">{title}</Title>
          <div className="flex flex-col justify-between items-center flex-wrap text-center min-h-full">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}
