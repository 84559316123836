import React, { useState, useEffect } from 'react'
import marked from 'marked'
import Button from './UI/Button'
import Panel from './UI/Panel'
import { FiShoppingCart } from 'react-icons/fi'
import { formatPrice, imageFormat } from '../utils'
import Slider from './UI/Slider'
import { FaAmazon } from 'react-icons/fa'
import Ads from './Ads'

function PanelProduct({ product, close, add }) {
  return (
    <Panel
      close={close}
      title={product.name}>
      <Slider photos={product.images.map(i => imageFormat(i, 'medium'))} />
      <div dangerouslySetInnerHTML={{ __html: marked(product.description) }} />
      <div>Prix : {formatPrice(product.price[0])} TTC</div>
      <div>
        <Button
          variant="primary"
          className="mx-auto my-4"
          Icon={FiShoppingCart}
          onClick={add}>
          Ajouter au panier
        </Button>
        <Button
          className="mx-auto my-4"
          onClick={close}>
          Fermer
        </Button>
      </div>
    </Panel>
  )
}

export function CartProduct({ product, className, remove }) {
  if (!product) return <div>Aucun produit</div>
  return (
    <div className={`flex ${className}`}>
      <div
        className="w-24 h-24 lg:w-32 lg:h-32 mr-4 flex-shrink-0 bg-cover bg-center rounded-sm"
        style={{ backgroundImage: `url("${imageFormat(product.images[0], 'small')}")` }} />
      <div className="flex flex-col justify-around items-start">
        <div className="font-light text-gray-800 text-base leading-tight lg:text-lg">
          {product.name}
        </div>
        <div className="font-light text-gray-800 text-base">
          {formatPrice(product.price[0])}
        </div>
        {remove &&
          <Button
            variant="secondary"
            className=""
            onClick={remove}>
            Enlever
          </Button>
        }
      </div>
    </div>
  )
}

export function Product({ product, order, className, add, openCart}) {
  const [more, setMore] = useState(false)
  const inCart = order && order.invoice && order.invoice.lines.data.find(l => product.stripeId === l.price.product)
  return (
    <div className={`flex flex-col justify-between w-32 ${className}`}>
      <div>
        <div
          onClick={() => setMore(true)}
          className="w-32 h-32 bg-cover bg-center rounded-sm cursor-pointer"
          style={{ backgroundImage: `url("${imageFormat(product.images[0], 'small')}")` }} />
        <div className="text-center my-3 font-light text-gray-800 text-sm leading-tight">
          {product.name} - {formatPrice(product.price[0])}
        </div>
      </div>
      {inCart &&
        <Button
          variant="secondary"
          className="mx-auto"
          onClick={() => openCart()}>
          {order.status === 'paid' ? 'Commande payée' : 'Accéder au panier'}
        </Button>
      }
      {!inCart &&
        <Button
          variant="secondary"
          className="mx-auto"
          onClick={() => setMore(true)}>
          En savoir plus
        </Button>
      }
      {more &&
        <PanelProduct
          product={product}
          close={() => setMore(false)}
          add={() => add().then(() => setMore(false))} />
      }
    </div>
  )
}

export default function Products({ products, order, className, add, openCart }) {
  if (products === null) return null
  let displayedProducts = (order && order.status === 'paid')
    ? products.filter(p => p.type === 'product' && order.invoice.lines.data.find(l => p.stripeId === l.price.product))
    : products.filter(p => p.type === 'product')
  return (
    <div className="flex flex-row lg:flex-col lg:justify-center max-w-full overflow-x-auto px-2 pb-2 lg:py-2 lg:px-0">
      {displayedProducts.map(product =>
        <Product
          key={product.id}
          product={product}
          order={order}
          openCart={openCart}
          className="mx-2 lg:my-4 lg:mx-0"
          add={() => add(product)} />
      )}
      <div className="flex lg:hidden">
        <Ads />
      </div>
      <div className="w-2 h-1 flex-shrink-0" />
    </div>
  )
}