const location = () => {
  if (typeof window !== `undefined`)
    return window.location
  else
    return { pathname: '', hostname: '' }
}

export const backUrl = (location().hostname === 'localhost') ? 'https://harcourt-back.wizito.com' : 'https://harcourt-back.wizito.com'

const match = (location().pathname.match(/photos\/(\d+)_(.+)/) || [])
const sessionId = match[1]
const token = match[2]
const authHeader = { headers: { 'Authorization': token } }

export function findSession() {
  return fetch(`${backUrl}/sessions/${sessionId}`, authHeader).then(res => res.json())
}

export function deleteSession() {
  return fetch(`${backUrl}/sessions/${sessionId}`, { method: 'DELETE', ...authHeader }).then(res => res.json())
}

export function setOptIn(value) {
  return fetch(`${backUrl}/sessions/${sessionId}/opt_in/${value}`, { method: 'PUT', ...authHeader }).then(res => res.json())
}

export function getProducts() {
  return fetch(`${backUrl}/products/`).then(res => res.json())
}

export function createOrder() {
  return fetch(`${backUrl}/orders/`, { method: 'POST', ...authHeader, body: JSON.stringify({ session: sessionId }) }).then(res => res.json())
}

export function addProduct(order, product) {
  return fetch(`${backUrl}/orders/${order.id}/products/${product.id}`, { method: 'POST', ...authHeader }).then(res => res.json())
}

export function removeProduct(order, product) {
  return fetch(`${backUrl}/orders/${order.id}/products/${product.id}`, { method: 'DELETE', ...authHeader }).then(res => res.json())
}

export function updateCustomer(order, customer) {
  return fetch(`${backUrl}/orders/${order.id}`, { method: 'PUT', ...authHeader, body: JSON.stringify(customer) }).then(res => res.json())
}

export function finalizeOrder(order) {
  return fetch(`${backUrl}/orders/${order.id}/finalize`, { method: 'POST', ...authHeader }).then(res => res.json())
}

export function refreshOrder(order) {
  return fetch(`${backUrl}/orders/${order.id}/refresh`, { method: 'POST', ...authHeader }).then(res => res.json())
}