import React, { useEffect } from 'react'

export function useKeyboardEvent(key, callback) {
  useEffect(() => {
    const handler = event =>
      (event.key === key ? callback() : null) 
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  })
}

export function formatPrice(price) {
  if (!price) return ''
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  })
  if (price.unit_amount === 0) return 'Offert'
  return `${formatter.format((price.unit_amount || price) / 100.0)}`
}

export function DisableBodyScroll() {
  return <style dangerouslySetInnerHTML={{ __html: 'body { overflow: hidden }' }} />
}

export function mod(a, n) {
  return ((a % n) + n) % n
}

export function imageFormat(image, format) {
  if (!image) return
  return (image.formats[format] || {}).url || image.url
}